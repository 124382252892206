import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Tooltip,
  useDisclosure,
  VStack,
  Text,
  Card,
  CardHeader,
  Heading,
  CardBody,
} from "@chakra-ui/react";
import { useRef } from "react";
import { RiExternalLinkLine } from "react-icons/ri";
import { useQueryClient } from "@tanstack/react-query";
import {
  isAxiosError,
  useApiMutation,
  useApiQuery,
} from "../../../../utilities/apibelRequest";
import useToast from "../../../../utilities/useToast";

export default function UseriCalSettingsCard() {
  const confirmDeleteiCalModalState = useDisclosure({ defaultIsOpen: false });
  const confirmDeleteiCalCancelRef = useRef<HTMLButtonElement>(null);
  const createiCalMutation = useApiMutation("userSettings/createiCal");
  const deleteiCalMutation = useApiMutation("userSettings/deleteiCal");

  const queryClient = useQueryClient();
  const { displayToast } = useToast();

  const iCalData = useApiQuery("userSettings/iCal", null);

  const handleClickSetupiCal = async () => {
    try {
      const result = await createiCalMutation.mutateAsync(null);
      queryClient.setQueriesData<typeof iCalData.data>(
        ["userSettings", "iCal"],
        (prev) =>
          prev === undefined
            ? undefined
            : { ...prev, useriCal: { url: result.url, key: result.key } },
      );
      displayToast({
        status: "success",
        title: "iCal Feed Created",
      });
    } catch (e) {
      displayToast({
        status: "error",
        title: "Failed to create iCal feed",
        description:
          isAxiosError(e) && e.response?.data.error === "ALREADY_EXISTS"
            ? "You already have an iCal feed setup"
            : undefined,
      });
    }
  };

  const handleClickDeleteiCal = async () => {
    try {
      await deleteiCalMutation.mutateAsync(null);
      queryClient.setQueriesData<typeof iCalData.data>(
        ["userSettings", "iCal"],
        (prev) => (prev ? { ...prev, useriCal: undefined } : undefined),
      );
      displayToast({
        status: "success",
        title: "iCal Feed Deleted",
      });
    } catch (e) {
      displayToast({
        status: "error",
        title: "Failed to delete iCal feed",
      });
      queryClient.invalidateQueries({queryKey: ["userSettings", "iCal"]});
    }
    confirmDeleteiCalModalState.onClose();
  };

  return (
    <>
      <Card w="full" variant="outline">
        <CardHeader>
          <Heading size="md">Calendar Feed</Heading>
        </CardHeader>
        {iCalData.isLoading ? (
          <CardBody>
            <VStack w="full" align="start">
              <Skeleton w="100%" h="8" />
              <Skeleton w="35%" h="8" />
            </VStack>
          </CardBody>
        ) : (
          <CardBody>
            <VStack align="start" w="full">
              {iCalData.isSuccess ? (
                !iCalData.data.accountHasiCalEnabled ? (
                  <Text>
                    iCal feeds are disabled on your account. They can be enabled
                    in Account Settings. Contact your account owner or
                    administrator for help.
                  </Text>
                ) : iCalData.data.useriCal !== undefined ? (
                  <>
                    <InputGroup>
                      <Input
                        pr="10"
                        readOnly
                        value={iCalData.data.useriCal.url}
                      />
                      <InputRightElement w="10">
                        <Tooltip label="Open in calendar">
                          <IconButton
                            variant="outline"
                            colorScheme="gray"
                            aria-label="Open calendar"
                            onClick={() =>
                              window.open(iCalData.data.useriCal.url)
                            }
                            icon={<Icon as={RiExternalLinkLine} />}
                          />
                        </Tooltip>
                      </InputRightElement>
                    </InputGroup>
                    <Text fontSize="sm">
                      Keep this URL secret. Anyone with this URL can add your
                      calendar to their calendar app.
                    </Text>
                    <Button
                      size="sm"
                      colorScheme="gray"
                      onClick={confirmDeleteiCalModalState.onOpen}
                      isDisabled={deleteiCalMutation.isLoading}>
                      Delete iCal feed
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={handleClickSetupiCal}
                    size="sm"
                    colorScheme="gray"
                    isLoading={createiCalMutation.isLoading}
                    loadingText="Generating iCal">
                    Set up iCal feed
                  </Button>
                )
              ) : (
                <Text>Something went wrong</Text>
              )}
            </VStack>
          </CardBody>
        )}
      </Card>

      <AlertDialog
        leastDestructiveRef={confirmDeleteiCalCancelRef}
        isOpen={confirmDeleteiCalModalState.isOpen}
        onClose={confirmDeleteiCalModalState.onClose}>
        <AlertDialogOverlay />
        <chakra-scope>
          <AlertDialogContent>
            <AlertDialogHeader>Delete iCal?</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete your iCal feed? You can re-create
              it later.
            </AlertDialogBody>
            <AlertDialogFooter>
              <HStack>
                <Button
                  onClick={confirmDeleteiCalModalState.onClose}
                  ref={confirmDeleteiCalCancelRef}
                  colorScheme="gray">
                  Cancel
                </Button>
                <Button
                  onClick={handleClickDeleteiCal}
                  colorScheme="red"
                  type="submit"
                  isLoading={deleteiCalMutation.isLoading}
                  loadingText="Deleting iCal">
                  Delete
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </chakra-scope>
      </AlertDialog>
    </>
  );
}
