import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  Avatar,
  Flex,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import UpdateUserSelect, { SelectableUser } from "./UpdateUserSelect";
import {
  useApiMutation,
  useApiQuery,
} from "../../../../utilities/apibelRequest";
import useToast from "../../../../utilities/useToast";

type Props = {
  manualResponsibleUser: {
    firstName?: string;
    lastName?: string;
    userID: string;
  };
  manualID: string;
  isOpen: boolean;
  onClose: () => void;
};

export function EditManualOwnerModal({
  manualResponsibleUser,
  manualID,
  isOpen,
  onClose,
}: Props) {
  const initialFocusRef = useRef(null);

  const toast = useToast();
  const queryClient = useQueryClient();

  const [selectedUser, setSelectedUser] = useState<SelectableUser | undefined>(
    undefined,
  );

  const userQuery = useApiQuery("user/selectable", null);
  const userData = userQuery.isSuccess ? userQuery.data : [];

  const updateManualResponsibleUserMutation = useApiMutation(
    "manual/updateManualResponsibleUser",
  );

  const isMutating = updateManualResponsibleUserMutation.isLoading;

  const handleClose = () => {
    setSelectedUser(undefined);
    onClose();
  };

  const handleConfirmUpdateUser = async () => {
    // do the mutation, then close the modal

    try {
      if (!selectedUser) {
        return;
      }
      await updateManualResponsibleUserMutation.mutateAsync({
        section: {
          manualID,
          responsibleUserID: selectedUser.ID,
        },
      });
    } catch (e) {
      toast.displayToast({
        title: "Failed to update owner",
        status: "error",
      });
    }
    toast.displayToast({
      title: "Updated manual owner",
      status: "success",
    });
    queryClient.invalidateQueries({queryKey: ["manual"]});

    handleClose();
  };

  return (
    <Modal
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      onClose={handleClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          <ModalHeader>Edit Manual Owner</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <VStack align="left">
                <VStack align="left" pb={4}>
                  <Text fontSize="sm" color="gray.500">
                    Current owner
                  </Text>
                  <Text>
                    <Avatar
                      size="xs"
                      name={`${manualResponsibleUser.firstName} ${manualResponsibleUser.lastName}`}
                    />{" "}
                    {`${manualResponsibleUser.firstName} ${manualResponsibleUser.lastName}`}
                  </Text>
                </VStack>
                <VStack align="left">
                  <Text fontSize="sm" color="gray.500">
                    Choose a new owner
                  </Text>
                  <UpdateUserSelect
                    users={userData}
                    selectedUser={selectedUser}
                    onChange={(value) => setSelectedUser(value)}
                  />
                </VStack>
              </VStack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={handleClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                isLoading={isMutating}
                loadingText="Updating owner..."
                onClick={handleConfirmUpdateUser}>
                Update Owner
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}
